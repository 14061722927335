@import url('https://fonts.googleapis.com/css2?family=Alata&family=Roboto&family=Russo+One&display=swap');


html {
  margin: 0;
  background-color: #353535;
  height: 100%;
  color: black;
  max-width: 100%;
  position: relative;
  font-size: 80%;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
